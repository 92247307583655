<template>
	<div class="OfferView background background--rot view">

		<template v-if="post">
			<DcOffer :post="post"></DcOffer>
		</template>

		<div class="view__spacer"></div>

		<DcFooter></DcFooter>

		<!--
		<pre name="post">{{post}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'
	import ViewMixin from './View.mixin.js'

	import DcOffer from '@/components/DcOffer.vue'
	import DcFooter from '@/components/DcFooter.vue'

	export default {
		name: 'FeatureView',
		components: {
			DcOffer,
			DcFooter,
		},
		mixins: [
			RestHandler,
			ViewMixin,
		],
		directives: {},
		props: {},
		data() {
			return {
				post: null,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
		},
		methods: {
			fetchPost( callback = ()=>{} ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : this.$route.params.slug,
					},
					callback : (response) => {
						const doLog  = false
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('this.fetchPost() callback', result.length)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						this.post = result[0]

						callback()
					},
				})
			}
		},
		mounted() {
			this.fetchPost(()=>{
				EventBus.$emit('pageLoaded', this.post)

				this.viewIsReady = true
			})
		},
	}
</script>

<style lang="less">
	@import "../less/includes/grid.include.less";

	.OfferView {}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
