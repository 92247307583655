<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<article class="DcOffer contentModule hSpace hSpace--colGap" :class="elmClasses" v-if="post">
		<div class="DcOffer__inner grid grid--setAreas vSpace vSpace--rowGap">
			<header class="DcOffer__header relative hSpace hSpace--colGap">
				<h3 class="DcOffer__overTitle">{{post.acf.overTitle}}</h3>
				<h1 class="DcOffer__title color color--rot">{{post.acf.title}}</h1>
				<br v-if="!$root.isMqBelowMd" />

				<router-link class="DcOffer__btn btn color color--rot"
					:to="{ path:'/angebote/', query: { filter : post.acf.route }}"
					v-if="post.acf.route"
				>{{post.acf.route}}</router-link>
				<!--
				<span class="DcOffer__btn btn color color--rot" v-if="post.acf.route">{{post.acf.route}}</span>
				-->
			</header>
			<div class="DcOffer__imageWrapper hSpace hSpace--colGap">
				<MhImage class="DcOffer__image"
					:imageObject="post.acf.image"
					:title="_.get( post, 'acf.image.title' )"
					:alt="_.get( post, 'acf.image.alt' )"
					:useRelativeUrl="true"
				></MhImage>
			</div>
			<main class="DcOffer__body DcOffer__sections" :class="{ 'hSpace hSpace--colGap' : $root.isMqBelowMd }">
				<div class="DcOffer__section underline underline--rot" v-for="(section, index) in post.acf.sections" :key="index">
					<h2 class="DcOffer__sectionTitle color color--rot">{{section.title}}</h2>
					<div v-html="section.text"></div><br />
				</div>
			</main>
		</div>
		<router-link class="DcOffer__backLink"
			:to="'/' + app.acfOptions.links.offersPost.post_name + '/'" v-if="app.acfOptions">
			<span class="DcOffer__iconLeftArrow"></span>
			Alle Angebote
		</router-link>
		<!--
		<pre name="..links.offerPost">{{app.acfOptions.links.offerPost}}</pre>
		 -->
	</article>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import Icon from '@/components/Icon.vue'

	export default {
		name: 'DcOffer',
		components: {
			MhImage,
			Icon,
		},
		mixins: [],
		props: {
			post: [Object, Boolean],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcOffer {
		&__inner {
			background-color: white;
			border-radius: 7px;
		}
		&__header,
		&__imageWrapper,
		&__body {
			[showBorders4] & {
				outline: 1px solid blue;
			}

			margin-top: -0.1em; // spacing fix
		}

		&__overTitle,
		&__title {
			max-width: 400px;
		}
		&__section {
			[showBorders4] & {
				outline: 1px solid red;
				background-color: fade( red, 25 );
			}
			&:last-child > br { display: none; } // hide last spacer br after section.text
			break-inside: avoid;
		}

		&__backLink {
			[showBorders4] & { .relative; .outline( green ); }

			display: flex;
			align-items: center;
			height: 50px;
			color: white;
		}
		&__iconLeftArrow {
			[showBorders4] & { .relative; .outline( green ); }

			display: inline-block;
			margin-right: 0.25em;
			width: 1.5em; height: 1.1em;
			transform: translateY(10%);

			background-image: url('../assets/imgs/icon--leftArrow.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: left;
		}

		@media @mediaQuery__dt {
			&__inner {
				grid-template-columns: repeat(8, 1fr);
				grid-template-areas:
					"a a c c c c c c"
					"b b c c c c c c";
			}

			&__header *:first-child { // adjust y-offset
				margin-top: -5px;
			}
			&__body &__section { // adjust y-offset
				transform: translateY(-5px);
			}

			&__sections {
				column-count: 2;
				column-gap: @app[dtColGap];
				display: inline-block;
			}
			&__section {
				padding-right: 0.75em;
			}
		}
		@media @mediaQuery__md {
			&__inner {
				grid-template-columns: repeat(8, 1fr);
				grid-template-areas:
					"a a a a a a a a"
					"b b c c c c c c";
			}

			&__header *:first-child { // adjust y-offset
				margin-top: -5px;
			}
			&__body {
				padding-right: 1em;
			}

			&__btn {
				position: absolute;
				top: 0; right: @app[mdColGap];
			}
			&__imageWrapper {
				padding-top: 3em;
			}
		}
		@media @mediaQuery__sm {
			.font--sizeSmall;

			&__inner {
				grid-template-columns: repeat(8, 1fr);
				grid-template-areas:
					"a a a a a a a a"
					"b b b b b b b b"
					"c c c c c c c c";
			}

			&__header *:first-child { // adjust y-offset
				margin-top: 0px;
			}
			&__body {
				padding-right: 1em;
			}

			&__btn {
				display: none;
			}
			&__image { // carlo hatte sich mehr hSpace gewünscht
				padding-left: 30px;
				padding-right: 30px;
			}
			&__imageWrapper {
				padding-top: 0.5em;
				padding-bottom: 0.5em;
			}

			&__backLink {
				height: 33px;
			}
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
